import styles from './index.module.scss'
import message from '@c/message'
import Image from '@c/image'
import { AREA_SEARCH_GET } from '@/constant'
import Link from 'next/link'
import { useRef } from 'react'
import cm from 'classnames'
import { useEffect, useState } from 'react'
import { useShowLogin, useWindowWidth } from '@/utils/hooks'
import { vodCollected, vodCancelCollected } from '@/api'
import EventBus from '@/utils/emitter'
import { pcStore } from '@/store'
import { useRecoilValue } from 'recoil'
import { hotSearchData, showSearchFocus } from '@/store'
import { useRouter } from 'next/router'
const updateCollectStatus = (data, id, val) => {
  const updateData = data.map(item => {
    if (item.id === id) {
      item.collect = val
    }
    return item
  })
  return updateData
}

const Index = props => {
  

  const {
    showTitle = true, //是否需要展示标题行
    data,
    isHome = false, //是否是首页
    source,
    top = 0,
    isDetail = false, //是否是详情页
    hideCollect = false,
    className,
    lessItems = false
  } = props
  const [list, setList] = useState(data.cards || [])
  const topData = useRecoilValue(hotSearchData)

  const router = useRouter()

  const toPlay = item => {
    if (item.label === '直播') {
      let url = `/live/${item.url ? item.url : ''}`
      if (router.pathname === '/live/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    } else {
      let url = `/vod/detail/${item.id}`
      if (router.pathname === '/vod/detail/[id]') {
        router.replace(url)
      } else {
        router.push(url)
      }
    }
  }

  return (
    <>
     

     <div>

     

     </div>
      <div className={cm(styles.container)}>
        
          <div className={styles.leftContainer}>
            <div className={styles.moduleName}>热映推荐</div>
          <div className={styles.content2}>
            {list.map((item,index)=>{
              return (
                <Link href={`/vod/detail/${item.id}`}>
                  <div className={cm(styles.cardList)}>


                  <Image className={styles.img} src={item.img || item.picurl} alt={item.name || '详情海报'} />

                    {/* <img src={item.img} className={styles.cover} /> */}
                    
                    {
                      item.state == 2?(
                        <img src='/api/images/gfxl.png' className={styles.official}  />
                      ):''
                    }

                     <div className={styles.TopNumContainer}>
                      {
                        index <=2?
                         (
                          <>
                          <img  src={`/api/images/top_num_${index+1}.png`} className={styles.top}  />
                          </>
                        )
                        : (
                          <>
                          <img  src='/api/images/top_num_index.png'  className={styles.top}  />
                          </>
                        )
                      }

                     
                        {/* <img  src='/api/images/top_num_1.png'  className={styles.top}  /> */}
                        <span className={styles.topNum}>{index+1}</span>
                     </div>
                     {
                      item.tmdbScore?(
                        <div className={styles.score}>
                          {item.tmdbScore.toFixed(1)}
                     </div>
                      ):null
                     }
                     {/* {item.tmdbScore}
                      {item.doubanScore}
                      {item.time}
                      {item.label} */}
                     <div className={cm('textEllipsis',styles.name)}>{item.name}</div>   
                     <div className={cm('textEllipsis',styles.desc)}>{item.desc}</div>  
                  </div>
                </Link>
              )


            })}
            </div>

          </div>

          {/* <div className={styles.rightContainer}>
          <div className={styles.moduleName}>
            搜索剧集榜单
            <img src='/api/images/search.png' className={styles.icon} />

          </div>

          <div className={styles.content}>
          {topData[0]?.map((item,index)=>{
              return (
                <div className={styles.item}>
                  <p
                      className={cm(styles.hotIndex, {
                        [styles.one]: item.index === 1,
                        [styles.two]: item.index === 2,
                        [styles.three]: item.index === 3
                      })}
                    >
                      {item.index}
                    </p>
                <div className={styles.name} onClick={() => toPlay(item)}> 
                  
                 <div  className={cm('textEllipsis')} >
                 {item.name}
                 </div>
               
                <span className={styles.label}>
                {item.label}
                  </span>
                  
                  
                </div>
                
                </div>
              )


            })}
            

            </div>
          </div> */}
      </div>
    </>
  )

  
}

export default Index
