import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Autoplay } from 'swiper'
import Image from '@c/image'
import FilterTab from '@c/filter-tab'
import styles from './index.module.scss'
import { useRef, useState, memo, useMemo, useEffect } from 'react'
import cm from 'classnames'
import Pagination from './pagination'
import Desc from './desc'
import Link from 'next/link'
import EventBus from '@/utils/emitter'
import { pcStore } from '@/store'
import { useRecoilValue } from 'recoil'

const filterData = data => {
  let da = data || []
  return da.filter(item => item.title)
}

const Index = ({ data, needTab = true, url }) => {



  const isPc = useRecoilValue(pcStore)
  const [list, setList] = useState(filterData(data))
  const swiper = useRef()
  const [activeIndex, setActive] = useState(0)

  // console.log("======  获取 BannerList  =====",list)

  useEffect(() => {
    EventBus.on('updateBanner', data => {
      setList(filterData(data))
    })
    return () => {
      EventBus.offAll('updateBanner')
    }
  }, [])

  // 性能处理
  const withOutActiveIndex = useMemo(() => {
    return (
      <>
        <Swiper
          ref={swiper}
          allowTouchMove={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          speed={300}
          loop={true}
          modules={[Autoplay]}
          onRealIndexChange={e => setActive(e.realIndex)}
        >
          {data.map(item => {
            return (
             
              <SwiperSlide key={item.title}>
                <Link href={`/vod/detail/${item.id}`}>
                  <Image
                    initSrc="/api/images/init_banner"
                    src={isPc ? item.poster : item.minPoster}
                    alt={item.title + '海报图'}
                    className={styles.bg}
                  />
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>

        {list.length > 1 ? (
          <>
            <div className={cm(styles.switch, styles.switchLeft, 'hideWhenPhone')}>
              <img loading="lazy" src="/api/images/ar" onClick={() => swiper.current.swiper.slidePrev()} alt="左切换" />
            </div>
            <div
              className={cm(styles.switch, styles.switchRight, 'hideWhenPhone')}
              onClick={() => swiper.current.swiper.slideNext()}
            >
              <img loading="lazy" src="/api/images/ar" alt="右切换" />
            </div>
          </>
        ) : null}
        <div className={cm(styles.leftMask, 'hideWhenPhone')}></div>

        {/* {needTab ? (
          <div className={cm(styles.bannerTab, styles.bannerTabPhone)}>
            <FilterTab active={1} url={url} />
          </div>
        ) : null} 
         
           <h1>首页数据</h1>
         
         */}
       
      </>
    )
  }, [isPc, data, list, needTab, url])


  
  

  return (
    <div className={cm(styles.slide, styles.slidePhone)}>
      {withOutActiveIndex}
      <div className={styles.mask}>
        {activeIndex} ==== 120
        <Desc data={{ ...list[activeIndex] }} />
        <Pagination data={list} active={activeIndex} />
        
        {isPc?(
          <>
        <div className={styles.pagination}>
      <div className={styles.items}>
        {list.map((item, index) => {
          return <span key={item.id} className={cm(styles.item, activeIndex === index ? styles.itemActive : '')}  
          onClick={()=>{swiper.current.swiper.slideTo(index)}}  >   
          <img  src={ item.minPoster }  />
          <div className={styles.mask}></div>
           </span>
        })}
      </div>
    </div>
          </>
        ):null}
        
      </div>


    </div>

    
  )
}
export default memo(Index)
